import { defineStore } from 'pinia'
import { type IItem } from '~/types/IItemsState'
import { useAuthenticatedFetch } from '~/composables/useAuthenticatedFetch'

export const useItemsStore = defineStore('items', () => {
  const item_name = ref('')
  const category_id = ref('')
  const items = ref<IItem[]>([])
  const pending = ref(false)
  const error = ref(null)

  const fetch = async () => {
    const requestOptions = {
      query: {
        category_id: category_id.value
      },
      immediate: false,
      watch: false
    } as Record<string, any>

    if (item_name.value.length > 0) {
      requestOptions.query['item_name'] = item_name.value
    }
    const { execute } = await useAuthenticatedFetch<IItem[]>(`/api/v1/shop/items`, {
      ...requestOptions,
      onRequest() {
        pending.value = true
      },
      onResponse(context) {
        pending.value = false
        items.value = context.response._data
      }
    })
    await execute()
  }

  return {
    item_name,
    category_id,
    items,
    pending,
    error,
    fetch
  }
})
